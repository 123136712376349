import { createReducer, on } from '@ngrx/store';
import { Member } from '@app/models/myoffice/member/member.model';

import { MemberModel } from '@app/models/system/member.model';
import { Login2State } from '@app/selectors/system/login2.select';
import { coutBonusSuccess, findIdSuccess, findPassWdSuccess, getLoginInfoSuccess, memberLogin, loginUserid2Success, logout, findMemberSuccess, setLoginMember2, getAccessTokenNaverSuccess, getInfoNaverSuccess, getLoginInfoOtherSuccess, updateLinkAccountSuccess, getInformationByUserId2Success } from '@app/actions/system/login2.action';
import { ResultProcessModel } from '@app/models/myoffice/result-process.model';
import { ResultProc } from '@app/models/system/result-proc.model';

export const login2SearchFeatureKey = 'login2Search';

export const initialState: Login2State = {
  memberItems: {} as MemberModel,
  count: 0,
  result: {} as ResultProcessModel,
  member: false,
  memberUserId2: {} as Member,
  findMemberItem: {} as MemberModel,
  token: new String,
  info: new String,
  resultLinkAccount: {} as ResultProc,
  infoUserId: {} as Member,
  findIdItem: {} as MemberModel,
}

export const login2SearchReducer = createReducer(
  initialState,
  on(getLoginInfoSuccess, (state, { member }) => ({ ...state, memberItems: member })),
  on(getLoginInfoOtherSuccess, (state, { member }) => ({ ...state, memberItems: member })),
  on(coutBonusSuccess, (state, { count }) => ({ ...state, count: count })),
  on(findIdSuccess, (state,  { member }) => ({...state, findIdItem: member})),
  on(findPassWdSuccess, (state,  { result }) => ({...state, result: result})),
  on(memberLogin, (state, {member})=> ({...state, member: member})),
  on(loginUserid2Success, (state, { memberUserId2 }) => ({ ...state, memberUserId2: memberUserId2 })),
  on(logout, (state) => ({...state, memberItems: {} as MemberModel, member: false, memberUserId2: {} as Member})),
  on(findMemberSuccess, (state, { findMember }) => ({ ...state, findMemberItem: findMember })),
  on(setLoginMember2, (state, { payload }) => ({ ...state, memberUserId2: payload })),
  on(getAccessTokenNaverSuccess, (state, { token }) => ({ ...state, token: token })),
  on(getInfoNaverSuccess, (state, { info }) => ({ ...state, info: info })),
  on(updateLinkAccountSuccess, (state, { result }) => ({ ...state, resultLinkAccount: result })),
  on(getInformationByUserId2Success, (state, { infoUserId }) => ({ ...state, infoUserId: infoUserId }))
);