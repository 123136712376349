import { environment } from "@enviroments/environment";
import { LangConstant } from "../constant/lang.constant";
import { AuthUtil } from "./auth.util";
import { ConvertUtil } from "./convert.util";
import { ValidationUtil } from "./validation.util";
import { MyProgram } from "@app/models/system/my-program.model";
import { Node } from "@app/models/components/node.model";

export class CommonUtils {

    static isConsumer() : boolean {
      if(AuthUtil.isLogined()) {
        return ConvertUtil.convertToString(AuthUtil.getLoginedInfo()?.userKindCd).includes("U00");
      }
      let infoConsumer = AuthUtil.getConsumerInformation();
      if(ValidationUtil.isNotNullAndNotEmpty(infoConsumer)) {
        return ConvertUtil.convertToString(infoConsumer?.userKindCd).includes("U00");
      }
      return false;
    }

    static getUserKind() : string {
      if(AuthUtil.isLogined()) {
        return ConvertUtil.convertToString(AuthUtil.getLoginedInfo()?.userKindCd);
      }
      return ConvertUtil.convertToString(AuthUtil.getInfoUserId2()?.userKindCd);
    }

    static plus(number1 : any, number2 : any) : number {
      return Number(number1) + Number(number2);
    }

    static minus(number1 : any, number2 : any) : number {
      return Number(number1) - Number(number2);
    }

    static getListFromCurrentMonth(currentMonth : number) {
      let totalMonth = 12 - currentMonth;
      if(totalMonth === 0 || currentMonth > 12 || currentMonth <= 0) {
        return Array.from({length : 12}, (e, i) => ConvertUtil.convertToZeroDecimal(CommonUtils.plus(i, 1)));
      }
      return Array.from({length: totalMonth}, (e, i) => ConvertUtil.convertToZeroDecimal(CommonUtils.plus(currentMonth, i + 1)));
    }

    static getListFromCurrentYear(currentYear : number) {
      return Array.from({length: 5}, (e, i) => {
        return ConvertUtil.convertToZeroDecimal(CommonUtils.plus(currentYear, i));
      });
    }

    static getUserId2() : string {
      if(AuthUtil.getLoginedInfo()) {
        return ConvertUtil.convertToString(AuthUtil.getLoginedInfo()?.userid2);
      }
      if(AuthUtil.getInfoUserId2()) {
        return ConvertUtil.convertToString(AuthUtil.getInfoUserId2()?.userid2);
      }
      return '';
    }

    static getLang(): string {
      let loginedInfo = AuthUtil.getLoginedInfo();
      let lang = sessionStorage.getItem(LangConstant.STR_LANG);
      if (ValidationUtil.isNullOrEmpty(lang)) {
        if (loginedInfo) {
          lang = ConvertUtil.convertToSring(loginedInfo.ctrCd);
        } else {
          lang = environment.default_lang;
        }
        sessionStorage.setItem(LangConstant.STR_LANG, lang);
      }

      return ConvertUtil.convertToSring(lang).toLowerCase();
    }

    static setDataSubMenuAndMain(myProgram : MyProgram[], menu : Map<string, Node<MyProgram>>, subMenu : MyProgram[]) : {menu : Map<string, Node<MyProgram>>, subMenu : MyProgram[]} {
      myProgram.forEach(v => {
        if(v.menuLv == '1') {
          menu.set(v.prgId, {data : v, children : []});
        }

        if(v.menuLv == '2') {
          const node = menu.get(v.pid);
          if (node !== undefined && node?.children !== undefined && node?.children !== null) {
            node.children.push({data : v, children : []});
          }
          subMenu.push(v);
        }
      });

      return {
        menu : menu,
        subMenu : subMenu
      }
    }

    static mapObject<TSource extends {}, TTarget>(source : TSource) : TTarget {
      let result : TTarget = {} as TTarget;
      return Object.assign(source, result);
    }

    static getMessages(): string {
      return 'messages.' + CommonUtils.getLangMessage();
    }

    static getLangMessage(): string {
      let res = environment.default_lang.toLowerCase();
      let lang = CommonUtils.getLang();

      switch (lang) {
        case 'kr':
          res = 'kr';
          break;
        case 'en':
          res = 'en';
          break;
        case 'us':
          res = 'en';
          break;
        default:
          res = 'en';
          break;
      }

      return res;
    }


}

