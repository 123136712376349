// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	urlBetaIzex: 'https://apl.beta-izex.ru/api/',
	keyBetaIzex: '88956aba576362ca65e2d1fcd3e9295a',

	ridirecUri: 'http://localhost:4500/login',
	//ridirecUri : 'https://www.purple-u.com/login',

	production: false,
	default_lang: 'US',
	comId: 'KHERUS',
	comCd: '01',
	apiUrl: 'https://v602.wownet.biz/api',
	//apiUrl: 'http://localhost:8100/api',
	urlWownet: 'https://v602.wownet.biz',
};
