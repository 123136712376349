<div id="login-successful" class="no-hf office-page">
    <div class="container">
        <a class="home-anchor" href="/">
            <img src="./assets/img/Logo.svg">
        </a>
        
        <h2 class="h-large">수당이 발생되었음을 진심으로 축하 드립니다.</h2>
        
        <p class="description">
            <span>{{ member.username }}</span> 
            님은 주민등록번호 수집 대상자이며, 수당을 받기 위해서는 주민등록번호 및 계좌번호가 필요합니다.
            아래 버튼을 클릭해서 주민등록번호 및 계좌정보를 확인해 주시기 바랍니다.
        </p>
        
        <div class="row btns">
            <a class="redirect-user-profile col-auto light-btn" href="/my-office/user-profile/change-resident-id">
                <span>지금확인</span>
                
            </a>
            <a class="redirect-my-office col-auto primary-btn" href="/my-office">
                <span>나중에확인</span>
                
            </a>
        </div>
        
        <div class="additional-info">
        <p class="warning">※ 주민등록번호를 입력하지 않을 경우 수당지급이 보류됩니다.<br/>
        ※ 입력하신 주민등록번호는 원천세 신고용으로 사용되며, 최종 신고일로부터 5년간 보관됩니다.</p>
        </div>				
    </div>
</div>