import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    constructor() { }

    public setItem(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }

    public getItem(key: string) {
        return sessionStorage.getItem(key);
    }
    public removeItem(key: string) {
        sessionStorage.removeItem(key);
    }

    public clearItem() {
        sessionStorage.clear();
    }
}