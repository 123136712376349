export class MyProgramConstant {
  static urlMyOffice = "/my-office";
  static urlShopping = "/shopping-mall";

  static kindMenuTopLeft = "ml";
  static kindMenuTopRight = "mr";
  static kindMenuHome = "mh";
  static kindMenuOffice = "mo";
  static kindMenuShopping = "ms";
  static kindMenuFooter = "mf";
  static kindSidebar = "mb";

  static officeId = "ml.My-office";
  static shoppingId = "ml.Shopping-mall";
  static homeId = "ml.Home";

  static URL_CENTER_BASE:string[] = [ "/brand" , "/membership" , "/treatment" , "/contact" , "/shopping-mall" , "/policy" ]
}